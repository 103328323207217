import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import 'dayjs/locale/pt-br';
import { api } from "../../services";

import { AgendaObj } from "../../store/ducks/agendaNotificacao/types";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import ListaAgendamentoComponent from "../ListaAgendamentoComponent";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const defaultAgenda: AgendaObj = {
  idAgenda: 0,
  nmTipoAgenda: "",
  idTipoAgenda: 0,
  dataAgenda: null,
  idObjeto: 0,
  nmObjeto: "",
  idDatabaseStatus: 1,
  tituloAgenda: "",
  corpoAgenda: "",
  customizada: 0
};

const ModalCadastroAgenda = (props: any) => {

  dayjs.locale("pt-br")

  const [isEdicao, setIsEdicao] = useState(false);
  const [listAgenda, setListAgenda] = useState<any>([]);
  const [agenda, setAgenda] = useState(defaultAgenda);
  const [inativaGravar, setInativaGravar] = useState(true);
  const [nmTipoAgendaError, setNmTipoAgendaError] = useState(false);
  const [dtAgendaError, setDtAgendaError] = useState(false);
  const [list, setList] = useState([]);
  const [type, setType] = useState(0);
  const [data, setData] = useState<Dayjs | null>(null);
  const [hora,setHora] = useState("");
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');



  useEffect(() => {
    if (props.agendasData) {
      handleRender(props.agendasData);
    }
  }, [props.agendasData]);

  useEffect(() => {
    if (!props.modalCadastroOpen) {
      handleClose();
    }
  }, [props.modalCadastroOpen]);

  useEffect(() => {
    
    if (!nmTipoAgendaError && !dtAgendaError && !!agenda.nmTipoAgenda && !!agenda.dataAgenda) {
      setInativaGravar(false);
    } else {
      setInativaGravar(true);
    }
  }, [agenda, nmTipoAgendaError, dtAgendaError]);

  useEffect(() => {
    if(hora != "" && data != null){
      const horas = data.format("YYYY-MM-DD") + "T" + hora + ":00.000-03:00";
      console.log("hora: ", horas);
      if(listAgenda.length > 1){
        let _listAgenda = new Array();
        listAgenda.forEach((e:any) => {
          e.dataAgenda = horas;
          _listAgenda.push(e);
        })
        setListAgenda(_listAgenda);
      }
      setAgenda({...agenda, dataAgenda: horas})
    }
  },[data,hora])

  useEffect(() => {
    console.log("Titulo : " + titulo)
    if(titulo != ""){ 
      
      setAgenda({...agenda, customizada: 1, tituloAgenda: titulo})
    }
    else if(descricao == "" && titulo == ""){
      setAgenda({...agenda, customizada: 0})
    }
  }, [titulo])

  useEffect(() => {
    console.log("Descricao : " + descricao)
    if(descricao != ""){
      setAgenda({...agenda, customizada: 1, corpoAgenda: descricao})
    } else if(descricao == "" && titulo == "") {
      setAgenda({...agenda, customizada: 0})
    }
  }, [descricao])

  const handleRender = (agendaData: any) => {
    if (agendaData.dataAgenda) {
      setIsEdicao(true);
      setData(dayjs(agendaData.dataAgenda))
      let myDate = dayjs(agendaData.dataAgenda).hour() + ":" + dayjs(agendaData.dataAgenda).minute()
      if(myDate.length < 4){myDate = 0 + myDate}
      if(myDate.length < 5 ){myDate = myDate + 0}
      setHora(myDate);
      typeSelector(JSON.stringify(agendaData.idTipoAgenda));
      setTitulo(JSON.stringify(agendaData.tituloAgenda).replaceAll('"', ''));
      setDescricao(JSON.stringify(agendaData.corpoAgenda).replaceAll('"', ''));
      
      setAgenda({
        ...agendaData,
      });
    }
  };

  

  const typeSelector = (tp : any) => {
    setType(0);
    console.log("TP:" , tp)
    if(tp == null || tp == ""){
      switch(agenda.idTipoAgenda) {
        case '1': 
          setAgenda({...agenda, nmTipoAgenda: "Story"});
          getStorys();
          break;
        case '2': 
          setAgenda({...agenda, nmTipoAgenda: "Conteúdo"});
          getConteudos();
          break;
        case '3': 
          setAgenda({...agenda, nmTipoAgenda: "Segurança do Trabalho"});
          getSegurancaDoTrabalho();
          break;
        case '4': 
          setAgenda({...agenda, nmTipoAgenda: "Treinamento de 1 Ponto"});
          getT1();
          break;
        case '5': 
          setAgenda({...agenda, nmTipoAgenda: "Comunicado"})
          getComunicados();
          break;
        case '6': 
          setAgenda({...agenda, nmTipoAgenda: "Curso"})
          getCurso();
          break;
        case '7': 
          setAgenda({...agenda, nmTipoAgenda: "Trilha"})
          getTrilha();
          break;
        case '8': 
          setAgenda({...agenda, nmTipoAgenda: "Avaliação"})
          getAvaliacao();
          break;
        default:
          break;
      }}else {
        switch(tp) {
          case '1': 
            setAgenda({...agenda, nmTipoAgenda: "Story"});
            getStorys();
            break;
          case '2': 
            console.log("CONTEUDO")
            setAgenda({...agenda, nmTipoAgenda: "Conteúdo"});
            getConteudos();
            break;
          case '3': 
            setAgenda({...agenda, nmTipoAgenda: "Segurança do Trabalho"});
            getSegurancaDoTrabalho();
            break;
          case '4': 
            setAgenda({...agenda, nmTipoAgenda: "Treinamento de 1 Ponto"});
            getT1();
            break;
          case '5': 
            setAgenda({...agenda, nmTipoAgenda: "Comunicado"})
            getComunicados();
            break;
          case '6': 
            setAgenda({...agenda, nmTipoAgenda: "Curso"})
            getCurso();
            break;
          case '7': 
            setAgenda({...agenda, nmTipoAgenda: "Trilha"})
            getTrilha();
            break;
          case '8': 
            setAgenda({...agenda, nmTipoAgenda: "Avaliação"})
            getAvaliacao();
            break;
          default:
            break;
        }}
  }

  useEffect(() => {
    typeSelector("");
  }, [agenda.idTipoAgenda])

  useEffect(() => {console.log(agenda)},[agenda])


  async function getStorys() {
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`story/listAllStoryToCombo`, {headers: {'Authorization': `Bearer ${token}`}})
        console.log("STORY");
        setType(1);
        setList(response);
        return response;
    } catch (error) {
        console.log(error);
    }
  }

  async function getConteudos() {
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`conteudo/listConteudoToCombo`, {headers: {'Authorization': `Bearer ${token}`}})
        setType(2);
        setList(response);
        return response;
    } catch (error) {
        console.log(error);
    }
  }
  async function getSegurancaDoTrabalho() {
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`segurancaNoTrabalho/listAllSegurancaNoTrabalhoToCombo`, {headers: {'Authorization': `Bearer ${token}`}})
        setType(3);
        setList(response);
        return response;
    } catch (error) {
        console.log(error);
    }
  }
  async function getT1() {
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`cursoT1/listAllCursoT1ToCombo`, {headers: {'Authorization': `Bearer ${token}`}})
        setType(4);
        setList(response);
        return response;
    } catch (error) {
        console.log(error);
    }
  }
  async function getComunicados() {
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`cursoComunicado/listAllCursoComunicadoToCombo`, {headers: {'Authorization': `Bearer ${token}`}})
        setType(5);
        setList(response);
        return response;
    } catch (error) {
        console.log(error);
    }
  }
  async function getCurso() {
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`curso/listAllCursoToCombo`, {headers: {'Authorization': `Bearer ${token}`}})
        setType(6);
        setList(response);
        return response;
    } catch (error) {
        console.log(error);
    }
  }
  async function getTrilha() {
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`trilha/listAllTrilhaToCombo`, {headers: {'Authorization': `Bearer ${token}`}})
        setType(7);
        setList(response);
        return response;
    } catch (error) {
        console.log(error);
    }
  }
  async function getAvaliacao() {
    const token = localStorage.getItem('accessToken');
    try {
        const { data: response } = await api.get(`avaliacao/listAllAvaliacaoCombo`, {headers: {'Authorization': `Bearer ${token}`}})
        setType(8);
        setList(response);
        return response;
    } catch (error) {
        console.log(error);
    }
  }
  const handleClose = () => {
    setIsEdicao(false);
    setInativaGravar(false);
    setNmTipoAgendaError(false);
    setDtAgendaError(false);
    setAgenda(defaultAgenda);
    setData(null);
    setHora("");
    setTitulo("");
    setDescricao("");
    props.closeModal();
  };

  const handlePersist = (e: any) => {
    e.preventDefault();
    if(listAgenda.length > 1){
      listAgenda.forEach((e:any) => {
        props.persistAgenda(isEdicao, e);
      })
    }else if(!listAgenda.length){
      props.persistAgenda(isEdicao, agenda);
    }
  };

  const cadastroAgenda = (ids: any, nm: any) => {
    if(ids.length == 1){
      setAgenda({
        ...agenda,
        idObjeto: ids[0],
        nmObjeto: nm
      });
    }else if(ids.length > 1){
      let _agendaList = []
      for (let i = 0; i < ids.length; i++) {
        let _agenda = {
          idAgenda: 0,
          nmTipoAgenda: agenda.nmTipoAgenda,
          idTipoAgenda: agenda.idTipoAgenda,
          dataAgenda: agenda.dataAgenda,
          idObjeto: ids[i],
          nmObjeto: nm[i],
          idDatabaseStatus: 1
        }
        _agendaList.push(_agenda);
      }
      setListAgenda(_agendaList);


    }
  }

  return (
    <Modal
      open={props.modalCadastroOpen}
      onClose={(e: any, res: any) => {
        if (res !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <Box sx={style}>
        <div className="modalHead">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdicao ? "Editar Agendamento de Notificação" : "Cadastro de novo Agendamento de Notificação"}
          </Typography>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <Divider />
        <form className="modalMid-form" onSubmit={handlePersist}>
          <Grid container spacing={2}>
          <FormControl sx={{ width: "50%", marginTop: 2, marginLeft: 2 }}>
            <InputLabel id="demo-controlled-open-select-label">
                Tipo do Agendamento
              </InputLabel>
             <Select
                required
                value={agenda.idTipoAgenda}
                label="Tipo de Agendamento"
                onChange={(e) => {
                  setAgenda({
                    ...agenda,
                    idTipoAgenda: e.target.value,
                  });
                }}
              >
                <MenuItem value="0">Escolha...</MenuItem>
                <MenuItem value="1">
                  Story
                </MenuItem>
                <MenuItem value="2">
                  Conteudo
                </MenuItem>
                <MenuItem value="3">
                  Segurança do Trabalho
                </MenuItem>
                <MenuItem value="4">
                Treinamento de 1 Ponto
                </MenuItem>
                <MenuItem value="5">
                  Comunicados
                </MenuItem>
                <MenuItem value="6">
                  Curso
                </MenuItem>
                <MenuItem value="7">
                  Trilha
                </MenuItem>
                <MenuItem value="8">
                  Avaliação
                </MenuItem>
              </Select> 
             </FormControl>
             <Grid item xs={4}>
                  <ListaAgendamentoComponent
                    getList={isEdicao?agenda.idObjeto:null}
                    listObjs={list}
                    type={type}
                    multiple={true}
                    onChange={(value:any, value2:any) => {
                      cadastroAgenda(value, value2)
                    }}
                  />
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                <DesktopDatePicker
                  onError={(e) => {
                    if (e) {
                      setDtAgendaError(true);
                    } else {
                      setDtAgendaError(false);
                    }
                  }}
                  label="Data do Agendamento"
                  value={data}
                  minDate={dayjs('1900-01-01')}
                  onChange={(newValue) => {
                    setDtAgendaError(false);
                    setData(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} error={dtAgendaError} helperText={dtAgendaError ? "Campo Obrigatório." : ''} />}
                />
              </LocalizationProvider>
            </Grid>
            <FormControl sx={{ width: "25%", marginTop: 2 }}>
            <InputLabel id="demo-controlled-open-select-label">
                Horário do Agendamento
              </InputLabel>
             <Select
                required
                value={hora}
                label="Horario do Agendamento"
                onChange={(e) => {
                 setHora(e.target.value)
                }}
              > <MenuItem value="">Escolha...</MenuItem>
                <MenuItem value="00:00">00:00</MenuItem>
                <MenuItem value="00:30">00:30</MenuItem>
                <MenuItem value="01:00">01:00</MenuItem>
                <MenuItem value="01:30">01:30</MenuItem>
                <MenuItem value="02:00">02:00</MenuItem>
                <MenuItem value="02:30">02:30</MenuItem>
                <MenuItem value="03:00">03:00</MenuItem>
                <MenuItem value="03:30">03:30</MenuItem>
                <MenuItem value="04:00">04:00</MenuItem>
                <MenuItem value="04:30">04:30</MenuItem>
                <MenuItem value="05:00">05:00</MenuItem>
                <MenuItem value="05:30">05:30</MenuItem>
                <MenuItem value="06:00">06:00</MenuItem>
                <MenuItem value="06:30">06:30</MenuItem>
                <MenuItem value="07:00">07:00</MenuItem>
                <MenuItem value="07:30">07:30</MenuItem>
                <MenuItem value="08:00">08:00</MenuItem>
                <MenuItem value="08:30">08:30</MenuItem>
                <MenuItem value="09:00">09:00</MenuItem>
                <MenuItem value="09:30">09:30</MenuItem>
                <MenuItem value="10:00">10:00</MenuItem>
                <MenuItem value="10:30">10:30</MenuItem>
                <MenuItem value="11:00">11:00</MenuItem>
                <MenuItem value="11:30">11:30</MenuItem>
                <MenuItem value="12:00">12:00</MenuItem>
                <MenuItem value="12:30">12:30</MenuItem>
                <MenuItem value="13:00">13:00</MenuItem>
                <MenuItem value="13:30">13:30</MenuItem>
                <MenuItem value="14:00">14:00</MenuItem>
                <MenuItem value="14:30">14:30</MenuItem>
                <MenuItem value="15:00">15:00</MenuItem>
                <MenuItem value="15:30">15:30</MenuItem>
                <MenuItem value="16:00">16:00</MenuItem>
                <MenuItem value="16:30">16:30</MenuItem>
                <MenuItem value="17:00">17:00</MenuItem>
                <MenuItem value="17:30">17:30</MenuItem>
                <MenuItem value="18:00">18:00</MenuItem>
                <MenuItem value="18:30">18:30</MenuItem>
                <MenuItem value="19:00">19:00</MenuItem>
                <MenuItem value="19:30">19:30</MenuItem>
                <MenuItem value="20:00">20:00</MenuItem>
                <MenuItem value="20:30">20:30</MenuItem>
                <MenuItem value="21:00">21:00</MenuItem>
                <MenuItem value="21:30">21:30</MenuItem>
                <MenuItem value="22:00">22:00</MenuItem>
                <MenuItem value="22:30">22:30</MenuItem>
                <MenuItem value="23:00">23:00</MenuItem>
                <MenuItem value="23:30">23:30</MenuItem> 
              </Select> 
             </FormControl>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={6} >
              <TextField
                
                sx={{ width: '100%' }}
                value={titulo}
                label="Título do Agendamento"
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setTitulo(e.target.value);
                }}
                onPaste={(e) => {
                  setTitulo(e.clipboardData.getData('Text'));
                  e.preventDefault();
                }}
                onCut={(e) => {
                  setTitulo("");
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                
                sx={{ width: '100%' }}
                value={descricao}
                label="Descrição do Agendamento"
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
                onPaste={(e) => {
                  setDescricao(e.clipboardData.getData('Text'));
                  e.preventDefault();
                }}
                onCut={(e) => {
                  setDescricao("");
                }}
              />
            </Grid>
            </Grid>
          <div className="cadastroFooter">
            <Divider />
            <Button variant="contained" disabled={inativaGravar} type="submit">
              {isEdicao ? "Gravar" : "Cadastrar"}
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalCadastroAgenda;
