import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from "@mui/material/Typography";
import * as AgendaActions from "../../store/ducks/agendaNotificacao/actions";

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";
import Loading from "../../Components/LoadingComponent";
import ModalCadastroAgenda from "../../Components/ModalCadastroAgendaNotificacao";
import AgendaTableComponent from "../../Components/TableAgendaNotificacao";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
  pagina: 0,
  tamanho: 10,
  ordem: "ASC",
  coluna: "nmTipoAgenda",
  txtBusca: "",
};

function Agenda({
  agenda,
  getAgendaTable,
  getAgendaData,
  onSaveAgenda,
  onUpdateAgenda,
  onInativarAgenda,
  clearAgendaData,
}: any) {
  const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
  const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
  const [body, setBody] = useState(_body);
  const [table, setTable] = useState(null);
  const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
  const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
  const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});

  useEffect(() => {
    if (agenda.error) {
      alert(
        "Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: " +
        agenda.errorMessage
      );
    }
  }, [agenda.error]);

  useEffect(() => {
    if (!modalCadastroOpen) {
      getAgendaTableData();
    }
  }, [body, modalCadastroOpen]);

  useEffect(() => {
    if (agenda.data.table != null) {
      setTable(agenda.data.table);
    }
  }, [agenda.data]);

  useEffect(() => {
    if (!agenda.loadingInativacao) {
      getAgendaTable(body);
    }
  }, [agenda.loadingInativacao]);

  useEffect(() => {
    if (!agenda.loadingPersis) {
      handleCloseAndReload();
    }
  }, [agenda.loadingPersis]);

  const getAgendaTableData = () => {
    getAgendaTable(body);
  };

  const handleCadastro = () => {
    clearAgendaData();
    setModalCadastroOpen(true);
  };

  const handleEdicao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione um Agendamento que gostaria de alterar");
    } else if (idsSelecionado.length > 1) {
      alert("Selecione apenas um Agendamento para alteração!");
    } else {
      getAgendaData(idsSelecionado[0]);
      setModalCadastroOpen(true);
    }
  };

  const changeTable = (__body: any) => {
    setBody({ ...__body });
  };

  const handlePersistAgenda = (edicao: boolean, agenda: any) => {
    if (edicao) {
      onUpdateAgenda(agenda);
    } else {
      onSaveAgenda(agenda);
    }
  };

  const handleCloseAndReload = () => {
    clearAgendaData();
    setModalCadastroOpen(false);
    setIdSelecionado([]);
  };

  const handleInativacao = () => {
    if (idsSelecionado.length == 0) {
      alert("Selecione os Agendamentos que gostaria de inativar");
    } else {
      setAlertConfirmacaoOpen(true);
      setAlertConfirmacaoTexto(EntidadesAcoes.AGENDA_EXCLUSAO);
      setAlertConfirmacaoAcao(() => () => {
        onInativarAgenda(idsSelecionado);
        setAlertConfirmacaoOpen(false);
        window.location.reload(); 
      })
      
    }
  };
  function unmountAlertConfirmacao() {
    setAlertConfirmacaoTexto('');
    setAlertConfirmacaoAcao(() => () => {})
    setAlertConfirmacaoOpen(false);
  }

  return (
    <div className="pageBox">
      <Loading loading={agenda.loading || agenda.loadingPersis} />
      <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
      <ModalCadastroAgenda
        modalCadastroOpen={modalCadastroOpen}
        closeModal={() => handleCloseAndReload()}
        agendasData={agenda.data.agenda}
        persistAgenda={handlePersistAgenda}
        getAgendaData={(idAgenda: any) => getAgendaData(idAgenda)}
      />
      <div className="menuLateral">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            width: "100%",
          }}
        >
          Ações
          <AcaoMenu
            onCadastro={handleCadastro}
            onEditar={handleEdicao}
            onInativar={handleInativacao}
          />
        </Typography>
      </div>
      <div className="table">
        <AgendaTableComponent
          loading={agenda.loadingTable}
          data={table}
          body={body}
          onChange={changeTable}
          selection={idsSelecionado}
          idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  agenda: state.agenda,
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...AgendaActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
