import { action } from "typesafe-actions";
import {AgendaNotificacaoTypes as AgendaNotificacaoTypes} from './types';

export const getAgendaTable = (data: any) => action(AgendaNotificacaoTypes.AGENDA_TABLE_REQUEST, data);
export const getAgendaTableSuccess = (data: any) => action(AgendaNotificacaoTypes.AGENDA_TABLE_REQUEST_SUCCESS, { data });
export const getAgendaTableFailure = (err: any) => action(AgendaNotificacaoTypes.AGENDA_TABLE_REQUEST_FAILURE, { err });

export const onInativarAgenda = (data: any) => action(AgendaNotificacaoTypes.AGENDA_INATIVA_REQUEST, data);
export const onInativarAgendaSuccess = (data: any) => action(AgendaNotificacaoTypes.AGENDA_DATA_REQUEST_SUCCESS, { data });
export const onInativarAgendaFailure = (err: any) => action(AgendaNotificacaoTypes.AGENDA_DATA_REQUEST_FAILURE, { err });

export const getAgendaData = (data: any) => action(AgendaNotificacaoTypes.AGENDA_DATA_REQUEST, data);
export const getAgendaDataSuccess = (data: any) => action(AgendaNotificacaoTypes.AGENDA_DATA_REQUEST_SUCCESS, { data });
export const getAgendaDataFailure = (err: any) => action(AgendaNotificacaoTypes.AGENDA_DATA_REQUEST_FAILURE, { err });

export const onSaveAgenda = (data: any) => action(AgendaNotificacaoTypes.AGENDA_SAVE_REQUEST, data);
export const onSaveAgendaSuccess = (data: any) => action(AgendaNotificacaoTypes.AGENDA_SAVE_REQUEST_SUCCESS, { data });
export const onSaveAgendaFailure = (err: any) => action(AgendaNotificacaoTypes.AGENDA_SAVE_REQUEST_FAILURE, { err });

export const onUpdateAgenda = (data: any) => action(AgendaNotificacaoTypes.AGENDA_UPDATE_REQUEST, data);
export const onUpdateAgendaSuccess = (data: any) => action(AgendaNotificacaoTypes.AGENDA_UPDATE_REQUEST_SUCCESS, { data });
export const onUpdateAgendaFailure = (err: any) => action(AgendaNotificacaoTypes.AGENDA_UPDATE_REQUEST_FAILURE, { err });

export const clearAgendaData = () => action(AgendaNotificacaoTypes.CLEAR_AGENDA_DATA);