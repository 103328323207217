import React, { useEffect, useState } from "react";
import { DataGrid, ptBR, GridToolbarContainer } from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { FormControl, InputLabel } from "@mui/material";

function CustomToolbar({ txtBusca, onChangePesquisa, titleTable, conteudo, contTipo, changeTipo, select, selectData, selectPlaceholder }: any) {
    const [value, setValue] = useState('');
    const [_contTipo, setContTipo] = useState(contTipo);

    useEffect(() => {
        setValue(txtBusca);
    }, [txtBusca]);

    const handleSelectTipo = (list: any) => {
        if (list.length == 0) {
            list = [0];
        } else if (list.length == 2 && list[0] == 0) {
            const index = list.indexOf(0);
            if (index > -1) {
                list.splice(index, 1);
            }
        }
        else if (list.length == 5) {
            list = [0];
        } else if (list.length > 1) {
            if (list.includes(0)) {
                list = [0];
            }
        }
        setContTipo(list);
        changeTipo(list);
    }

    return (
        <GridToolbarContainer sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '10px' }}>
            <Typography variant="h6" noWrap component="div">
                {titleTable}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {
                    conteudo ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: '1em' }}>
                            <div style={{ marginRight: '1em' }}>
                                Tipo de Conteúdo
                            </div>
                            <Select
                                sx={{ maxWidth: 250 }}
                                size="small"
                                multiple
                                value={_contTipo}
                                onChange={(e: any) => handleSelectTipo(e.target.value)}
                            >
                                <MenuItem value={0}>Todos</MenuItem>
                                <MenuItem value={2}>T1</MenuItem>
                                <MenuItem value={5}>Comunicados</MenuItem>
                                <MenuItem value={6}>Documentos</MenuItem>
                                <MenuItem value={7}>Multimídia</MenuItem>
                                <MenuItem value={8}>Games</MenuItem>
                                <MenuItem value={9}>Stories</MenuItem>
                            </Select>
                        </div>
                        : null
                }

                {
                    select && selectPlaceholder ? (
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="demo-controlled-open-select-label">
                                {selectPlaceholder}
                            </InputLabel>
                            <Select
                                size="medium"
                                sx={{
                                    minWidth: '200px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                variant="outlined"
                                id="outlined-basic"
                                value={value}
                                label={selectPlaceholder}
                                defaultValue={""}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                    onChangePesquisa(e.target.value)
                                }}
                            >
                                <MenuItem value="">{selectPlaceholder}...</MenuItem>
                                {selectData
                                    ? selectData.map((item: any, idx: any) => (
                                        <MenuItem key={"item-" + idx} value={window.location?.pathname.includes('justificativas') && item.idTipoJustificativa}>
                                            {window.location?.pathname.includes('justificativas') && item.dsTipoJustificativa}
                                        </MenuItem>
                                    ))
                                    : null}
                            </Select>
                        </FormControl>

                    ) : (
                        <TextField id="outlined-basic" value={value} label="Pesquisar" variant="outlined" size="small" onChange={e => {
                            setValue(e.target.value);
                            onChangePesquisa(e.target.value)
                        }} />
                    )
                }
            </div>
        </GridToolbarContainer>
    );
}

const CustomSelectItensPerPage = ({ size, onChageItensPerPage }: any) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ marginRight: '1em' }}>
                Linhas por página
            </div>
            <Select size="small" value={size || 10} onChange={e => onChageItensPerPage(e.target.value)} >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
            </Select>
        </div>
    )
}

const CustomPagination = ({ page, pageCount, pageChange }: any) => {
    return (
        <Pagination
            color="standard"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => pageChange(value)}
        />
    );
}

const CustomFooterCount = ({ offset, numberElements, totalElements, page, pageCount, pageChange, size, onChageItensPerPage }: any) => {
    return (
        <Box sx={{ padding: "10px", display: "flex", width: '100%', justifyContent: 'space-between' }}>
            Mostrando de {offset + 1} até {offset + numberElements} de {totalElements} registros
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '600px' }}>
                <CustomSelectItensPerPage size={size} onChageItensPerPage={onChageItensPerPage} />
                <CustomPagination page={page} pageCount={pageCount} pageChange={pageChange} />
            </div>
        </Box >
    );
}

const GenericTable = (props: any) => {
    const pageChange = (page: any) => {
        props.onPageChange(page);
    }

    const sizeChange = (size: any) => {
        props.sizeChange(size)
    }
    return (
        <Box sx={{ width: '100%', backgroundColor: '#fff' }} >
            <DataGrid
                loading={props.loading}
                page={props.page}
                rows={props.rows}
                columns={props.columns}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                pagination
                autoHeight
                checkboxSelection={true}
                selectionModel={props.selection}
                onSelectionModelChange={newSelection => {
                    props.idSelectionChange(newSelection);
                }}
                onSortModelChange={(model, details) => {
                    console.log(model)
                    props.sortChange(model[0])
                }}
                components={{
                    Footer: CustomFooterCount,
                    Toolbar: CustomToolbar,
                    LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                    toolbar: {
                        txtBusca: props.txtBusca,
                        onChangePesquisa: props.onChangePesquisa,
                        titleTable: props.titleTable,
                        conteudo: props.isConteudo || false,
                        contTipo: props.contTipo,
                        changeTipo: props.changeTipo,
                        select: props.select,
                        selectData: props.selectData,
                        selectPlaceholder: props.selectPlaceholder
                    },
                    footer: {
                        offset: props.offset,
                        numberElements: props.numberElements,
                        totalElements: props.totalElements,
                        page: props.page,
                        pageCount: props.totalPages,
                        pageChange: pageChange,
                        size: props.size,
                        onChageItensPerPage: sizeChange
                    },
                    loadingOverlay: {
                        color: 'secondary'
                    }
                }}
            />
        </Box>
    )
};

export default GenericTable;