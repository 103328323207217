import { Reducer } from "@reduxjs/toolkit";
import { AgendaNotificacaoTypes, AgendaState } from "./types";

const INITIAL_STATE: AgendaState = {
    data: {
        table: null,
        agenda: null
    },
    error: false,
    errorMessage: null,
    loading: false,
    loadingTable: false,
    loadingPersis: false,
    loadingInativacao: false
}

const reducer: Reducer<AgendaState> = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case AgendaNotificacaoTypes.AGENDA_TABLE_REQUEST:
            return {
                ...state,
                error: false,
                loadingTable: true,
            };
        case AgendaNotificacaoTypes.AGENDA_TABLE_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loadingTable: false,
                data: {
                    ...state.data,
                    table: action.payload.data
                }
            };
        case AgendaNotificacaoTypes.AGENDA_TABLE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingTable: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    table: null
                }
            };


        case AgendaNotificacaoTypes.AGENDA_INATIVA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                loadingInativacao: true,
            };
        case AgendaNotificacaoTypes.AGENDA_INATIVA_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                loadingInativacao:false
            };
        case AgendaNotificacaoTypes.AGENDA_INATIVA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                loadingInativacao: false,
                errorMessage: action.payload.err
            };


        case AgendaNotificacaoTypes.AGENDA_DATA_REQUEST:
            return {
                ...state,
                error: false,
                loading: true
            };
        case AgendaNotificacaoTypes.AGENDA_DATA_REQUEST_SUCCESS: 
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    agenda: action.payload.data
                }
            };
        case AgendaNotificacaoTypes.AGENDA_DATA_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: action.payload.err,
                data: {
                    ...state.data,
                    agenda: null
                }
            }

        case AgendaNotificacaoTypes.CLEAR_AGENDA_DATA:
            return {
                ...state,
                error: false,
                loading: false,
                data: {
                    ...state.data,
                    agenda: null
                }
            };
        
        case AgendaNotificacaoTypes.AGENDA_SAVE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true
            };
        case AgendaNotificacaoTypes.AGENDA_SAVE_REQUEST_SUCCESS:
            alert('Cadastro de Agendamento de Notificações realizado com sucesso!');
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    agenda: action.payload.data
                }
            };
        case AgendaNotificacaoTypes.AGENDA_SAVE_REQUEST_FAILURE:
            return {
                ...state,
                error: true,
                loadingPersis: false,
                errorMessage: action.payload.err
            };

        case AgendaNotificacaoTypes.AGENDA_UPDATE_REQUEST:
            return {
                ...state,
                error: false,
                loadingPersis: true
            };
        case AgendaNotificacaoTypes.AGENDA_UPDATE_REQUEST_SUCCESS:
            alert("Edição de Agendamento de Notificações realizado com sucesso!")
            return {
                ...state,
                error: false,
                loadingPersis: false,
                data: {
                    ...state.data,
                    agenda: action.payload.data
                }
            };
        case AgendaNotificacaoTypes.AGENDA_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error:true,
                loadingPersis:false,
                errorMessage: action.payload.err
            }
            default:
                return {
                    ...state,
                    error: false,
                    loading: false,
                    errorMessage: null
                };
}};

export default reducer;