import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { MultiSelect } from "primereact/multiselect";
import { Skeleton } from "primereact/skeleton";

import * as UtilsActions from "../../store/ducks/utils/actions";
import { ApplicationState } from "../../store";

function ListAgendamento({
  utils,
  getList,
  listObjs,
  onChange,
  multiple,
  error,
  type,
  onChangeName
}: any) {

  const [list, setList] = React.useState<any>([]);
  const [listSelecionado, setListSelecionado] = React.useState<any>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [lazyItems, setLazyItems] = React.useState<any>([]);
  const [lazyLoading, setLazyLoading] = React.useState(false);
  const [options, setOptions] = React.useState("");
  const [values, setValues] = React.useState("");
  const [placeholder, setPlaceholder] = React.useState("...");
  const [placeholderPesquisa, setPlaceholderPesquisa] = React.useState("...");

  const loadLazyTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(
    null
  );

  React.useEffect(() => {
    if (getList) {
      console.log(getList)
      setListSelecionado(
        [getList]
      );
    }
  }, [getList]);

  React.useEffect(() => {
    if(type == 0) {
      setOptions("")
      setValues("")
      setPlaceholder("...")
      setPlaceholderPesquisa("...")
    }
    if(type == 1) {
      setOptions("nmStory")
      setValues("idStory")
      setPlaceholder("Selecione os Stories")
      setPlaceholderPesquisa("Busque por um story")
    }
    if(type == 2) {
      setOptions("nmConteudo")
      setValues("idConteudo")
      setPlaceholder("Selecione os Conteudos")
      setPlaceholderPesquisa("Busque por um conteudo")
    }
    if(type == 3) {
      setOptions("nmSegurancaNoTrabalho")
      setValues("idSegurancaNoTrabalho")
      setPlaceholder("Selecione as Seguranças no Trabalho")
      setPlaceholderPesquisa("Busque por uma segurança no trabalho")
    }
    if(type == 4) {
      setOptions("nmCursoT1")
      setValues("idCursoT1")
      setPlaceholder("Selecione os Treinamentos de 1 Ponto")
      setPlaceholderPesquisa("Busque por um treinamento de 1 ponto")
    }
    if(type == 5) {
      setOptions("nmCursoComunicado")
      setValues("idCursoComunicado")
      setPlaceholder("Selecione os Comunicados")
      setPlaceholderPesquisa("Busque por um comunicado")
    }
    if(type == 6) {
      setOptions("nmCurso")
      setValues("idCurso")
      setPlaceholder("Selecione os Cursos")
      setPlaceholderPesquisa("Busque por um curso")
    }
    if(type == 7) {
      setOptions("nmTrilha")
      setValues("idTrilha")
      setPlaceholder("Selecione as Trilhas")
      setPlaceholderPesquisa("Busque por uma trilha")
    }
    if(type == 8) {
      setOptions("nmAvaliacao")
      setValues("idAvaliacao")
      setPlaceholder("Selecione as Avaliações")
      setPlaceholderPesquisa("Busque por uma avaliação")
    }
  },[type])

 

  const onLazyLoad = (event: any) => {
    setLazyLoading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    loadLazyTimeout.current = setTimeout(() => {
      const { first, last } = event;
      const _lazyItems = [...lazyItems];

      for (let i = first; i < last; i++) {
        _lazyItems[i] = { label: `Item #${i}`, value: i };
      }

      setLazyItems(_lazyItems);
      setLazyLoading(false);
    }, 300);
  };


  const addName = (item: any, id: any) => {
    if(type == 1) {
      let nms:any = [];
      item.map((i:any) => {
            if (id.length == 1 && i.idStory == id){
              onChange(id, i.nmStory)
            }else if(id.length > 1){
              id.forEach((e:any) => {
                if(i.idStory === e){
                  nms.push(i.nmStory);
                }
              });
            }
      })
      if (id.length > 1) {
        onChange(id, nms);
      }
      
      
    }
    if(type == 2) {
      let nms:any = [];
      item.map((i:any) => {
            if (id.length == 1 && i.idConteudo == id){
              onChange(id, i.nmConteudo)
            }else if(id.length > 1){
              id.forEach((e:any) => {
                if(i.idConteudo === e){
                  nms.push(i.nmConteudo);
                }
              });
            }
      })
      if (id.length > 1) {
        onChange(id, nms);
      }
    }
    if(type == 3) {
      let nms:any = [];
      item.map((i:any) => {
            if (id.length == 1 && i.idSegurancaNoTrabalho == id){
              onChange(id, i.nmSegurancaNoTrabalho)
            }else if(id.length > 1){
              id.forEach((e:any) => {
                if(i.idSegurancaNoTrabalho === e){
                  nms.push(i.nmSegurancaNoTrabalho);
                }
              });
            }
      })
      if (id.length > 1) {
        onChange(id, nms);
      }
    }
    if(type == 4) {
      let nms:any = [];
      item.map((i:any) => {
            if (id.length == 1 && i.idCursoT1 == id){
              onChange(id, i.nmCursoT1)
            }else if(id.length > 1){
              id.forEach((e:any) => {
                if(i.idCursoT1 === e){
                  nms.push(i.nmCursoT1);
                }
              });
            }
      })
      if (id.length > 1) {
        onChange(id, nms);
      }
    }
    if(type == 5) {
      let nms:any = [];
      item.map((i:any) => {
            if (id.length == 1 && i.idCursoComunicado == id){
              onChange(id, i.nmCursoComunicado)
            }else if(id.length > 1){
              id.forEach((e:any) => {
                if(i.idCursoComunicado === e){
                  nms.push(i.nmCursoComunicado);
                }
              });
            }
      })
      if (id.length > 1) {
        onChange(id, nms);
      }
    }
    if(type == 6) {
      let nms:any = [];
      item.map((i:any) => {
            if (id.length == 1 && i.idCurso == id){
              onChange(id, i.nmCurso)
            }else if(id.length > 1){
              id.forEach((e:any) => {
                if(i.idCurso === e){
                  nms.push(i.nmCurso);
                }
              });
            }
      })
      if (id.length > 1) {
        onChange(id, nms);
      }
    }
    if(type == 7) {
      let nms:any = [];
      item.map((i:any) => {
            if (id.length == 1 && i.idTrilha == id){
              onChange(id, i.nmTrilha)
            }else if(id.length > 1){
              id.forEach((e:any) => {
                if(i.idTrilha === e){
                  nms.push(i.nmTrilha);
                }
              });
            }
      })
      if (id.length > 1) {
        onChange(id, nms);
      }
    }
    if(type == 8) {
      let nms:any = [];
      item.map((i:any) => {
            if (id.length == 1 && i.idAvaliacao == id){
              onChange(id, i.nmAvaliacao)
            }else if(id.length > 1){
              id.forEach((e:any) => {
                if(i.idAvaliacao === e){
                  nms.push(i.nmAvaliacao);
                }
              });
            }
      })
      if (id.length > 1) {
        onChange(id, nms);
      }
    }
  }


  return (
    <>  
    <MultiSelect
        style={{
          width: "100%",
          height: "100%"
        }}
        selectionLimit={multiple ? 99999 : 1}
        showSelectAll={multiple}
        value={listSelecionado}
        options={listObjs}
        display="chip"
        onChange={(e) => {
          if (e.value) {
            setListSelecionado(e.value);
            setSelectAll(e.value.length === listObjs.length);
            addName(listObjs, e.value)
            
          } else {
            setSelectAll(false);
            setListSelecionado([]);
            onChange([]);
          }
        }}
        selectAll={selectAll}
        optionLabel={options}
        optionValue={values}
        placeholder={
          placeholder
        }
        filter
        showClear={true}
        className={error ? "p-multiselect-error" : ""}
        maxSelectedLabels={3}
        selectedItemsLabel={`${
          listSelecionado ? listSelecionado.length : 0
        } stories selecionados`}
        emptyFilterMessage="Não encontrado" 
        filterPlaceholder={placeholderPesquisa}
        resetFilterOnHide={true}
        virtualScrollerOptions={{
          lazy: true,
          onLazyLoad: onLazyLoad,
          itemSize: 43,
          showLoader: true,
          loading: lazyLoading,
          delay: 1,
          loadingTemplate: (options) => {
            return (
              <div
                className="flex align-items-center p-2"
                style={{ height: "48px" }}
              >
                <Skeleton width={options.even ? "70%" : "60%"} height="1.5rem" />
              </div>
            );
          },
        }}
      />
   </>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UtilsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListAgendamento);
