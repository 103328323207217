import { Dayjs } from "dayjs"

export enum AgendaNotificacaoTypes {
    AGENDA_TABLE_REQUEST = 'agendaNotificacao/AGENDA_TABLE_REQUEST',
    AGENDA_TABLE_REQUEST_SUCCESS = 'agendaNotificacao/AGENDA_TABLE_REQUEST_SUCCESS',
    AGENDA_TABLE_REQUEST_FAILURE = 'agendaNotificacao/AGENDA_TABLE_REQUEST_FAILURE',

    AGENDA_INATIVA_REQUEST = 'agendaNotificacao/AGENDA_INATIVA_REQUEST',
    AGENDA_INATIVA_REQUEST_SUCCESS = 'agendaNotificacao/AGENDA_INATIVA_REQUEST_SUCCESS',
    AGENDA_INATIVA_REQUEST_FAILURE = 'agendaNotificacao/AGENDA_INATIVA_REQUEST_FAILURE',

    CLEAR_AGENDA_DATA = 'agendaNotificacao/CLEAR_AGENDA_DATA',

    AGENDA_DATA_REQUEST = 'agendaNotificacao/AGENDA_DATA_REQUEST',
    AGENDA_DATA_REQUEST_SUCCESS = 'agendaNotificacao/AGENDA_DATA_REQUEST_SUCCESS',
    AGENDA_DATA_REQUEST_FAILURE = 'agendaNotificacao/AGENDA_DATA_REQUEST_FAILURE',

    AGENDA_SAVE_REQUEST = 'agendaNotificacao/AGENDA_SAVE_REQUEST',
    AGENDA_SAVE_REQUEST_SUCCESS = 'agendaNotificacao/AGENDA_SAVE_REQUEST_SUCCESS',
    AGENDA_SAVE_REQUEST_FAILURE = 'agendaNotificacao/AGENDA_SAVE_REQUEST_FAILURE',

    AGENDA_UPDATE_REQUEST = 'agendaNotificacao/AGENDA_UPDATE_REQUEST',
    AGENDA_UPDATE_REQUEST_SUCCESS = 'agendaNotificacao/AGENDA_UPDATE_REQUEST_SUCCESS',
    AGENDA_UPDATE_REQUEST_FAILURE = 'agendaNotificacao/AGENDA_UPDATE_REQUEST_FAILURE',
}

export interface AgendaObjTable {
    id?: number,
    nmTipoAgenda?: string
    dataAgenda?: string
    nmObjeto?: string
}


export interface AgendaObj {
    idAgenda?: string | number
    nmTipoAgenda?: string
    idTipoAgenda?: number | string
    dataAgenda?: null | Dayjs | string
    idObjeto?: null | number
    nmObjeto?: string
    idDatabaseStatus?: number
    tituloAgenda?: null | string
    corpoAgenda?: null | string
    customizada?: null | number
}


export interface AgendaTable {
    [x: string]: any
    content: Array<Object>,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    numberOfElements: number
}

export interface AgendaPage {
    readonly agenda: AgendaObj | null,
    readonly table: AgendaTable | null,
}

export interface AgendaState {
    readonly data: AgendaPage,
    readonly loading: boolean,
    readonly loadingTable: boolean,
    readonly loadingPersis: boolean,
    readonly loadingInativacao: boolean,
    readonly error: boolean,
    readonly errorMessage: string | null,
}
