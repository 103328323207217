import { all, takeLatest } from "redux-saga/effects";

import { doLogoutApp, loginRequest, refreshToken } from "./auth/sagas";
import { AuthTypes } from "./auth/types";
import {
  getAvaliacaoData,
  getAvaliacaoTable,
  getAvaliacaoTipoList,
  onAtivarAvaliacao,
  onEnviaNotificacaoAvaliacao,
  onImportarAvalicaoPerguntaEResposta,
  onInativarAvaliacao,
  onPersistAvaliacaoPergunta,
  onPersistPerguntaResposta,
  onSaveAvaliacao,
  onUpdateAvaliacao,
} from "./avaliacao/sagas";
import { AvaliacaoTypes } from "./avaliacao/types";
import {
  getClusterData,
  getClusterTable,
  onInativarCluster,
  onSaveCluster,
  onUpdateCluster,
} from "./cluster/sagas";
import { ClusterTypes } from "./cluster/types";
import {
  getComunicadoData,
  getComunicadoTable,
  onAtivarComunicado,
  onInativarComunicado,
  onNotificacaoComunicado,
  onSaveComunicado,
  onUpdateComunicado,
} from "./comunicado/sagas";
import { ComunicadoTypes } from "./comunicado/types";
import {
  getConteudoData,
  getConteudoTable,
  getConteudoTipoList,
  onAtivarConteudo,
  onInativarConteudo,
  onNotificacaoConteudo,
  onSaveConteudo,
  onUpdateConteudo,
} from "./conteudo/sagas";
import { ConteudoTypes } from "./conteudo/types";
import {
  getSegurancaNoTrabalhoData,
  getSegurancaNoTrabalhoTable,
  onAtivarSegurancaNoTrabalho,
  onInativarSegurancaNoTrabalho,
  onNotificacaoSegurancaNoTrabalho,
  onSaveSegurancaNoTrabalho,
  onUpdateSegurancaNoTrabalho,
} from "./segurancaNoTrabalho/sagas";
import { SegurancaNoTrabalhoTypes } from "./segurancaNoTrabalho/types";
import {
  getCursoData,
  getCursoTable,
  onAtivarCurso,
  onInativarCurso,
  onNotificacaoCurso,
  onSaveCurso,
  onUpdateCurso,
} from "./curso/sagas";
import { CursoTypes } from "./curso/types";
import {
  getDestaqueData,
  getDestaqueTable,
  onAtivarDestaque,
  onInativarDestaque,
  onSaveDestaque,
  onUpdateDestaque,
} from "./destaque/sagas";
import { DestaqueTypes } from "./destaque/types";
import {
  getGrupoData,
  getGrupoTable,
  onInativarGrupo,
  onSaveGrupo,
  onUpdateGrupo,
} from "./grupo/sagas";
import { GrupoTypes } from "./grupo/types";
import {
  getAgendaData,
  getAgendaTable,
  onInativarAgenda,
  onSaveAgenda,
  onUpdateAgenda,
} from "./agendaNotificacao/sagas";
import { AgendaNotificacaoTypes } from "./agendaNotificacao/types";
import {
  getJustificativaData,
  getJustificativaTable,
  getJustificativaTipoList,
  onInativarJustificativa,
  onSaveJustificativa,
  onUpdateJustificativa,
} from "./justificativa/sagas";
import { JustificativaTypes } from "./justificativa/types";
import {
  getPerfilData,
  getPerfilExistente,
  getPerfilTable,
  onAtivarPerfil,
  onInativarPerfil,
  onSavePerfil,
  onUpdatePerfil,
} from "./perfil/sagas";
import { PerfilTypes } from "./perfil/types";
import {
  getUrlExternaData,
  getUrlExternaTable,
  onAtivarUrlExterna,
  onInativarUrlExterna,
  onNotificacaoUrlExterna,
  onSaveUrlExterna,
  onUpdateUrlExterna,
} from "./pesquisaExt/sagas";
import { UrlExternaTypes } from "./pesquisaExt/types";
import { getRelatorio } from "./relatorio/sagas";
import { RelatorioTypes } from "./relatorio/types";
import {
  getSubclusterData,
  getSubclusterTable,
  onInativarSubcluster,
  onSaveSubcluster,
  onUpdateSubcluster,
} from "./subcluster/sagas";
import { SubclusterTypes } from "./subcluster/types";
import {
  getT1Data,
  getT1Table,
  onAtivarT1,
  onInativarT1,
  onNotificacaoT1,
  onSaveT1,
  onUpdateT1,
} from "./t1/sagas";
import { T1Types } from "./t1/types";
import {
  getTemaData,
  getTemaExistente,
  getTemaTable,
  onAtivarTema,
  onInativarTema,
  onSaveTema,
  onUpdateTema,
} from "./temas/sagas";
import { TemaTypes } from "./temas/types";
import {
  getTrilhaData,
  getTrilhaTable,
  onAtivarTrilha,
  onInativarTrilha,
  onNotificacaoTrilha,
  onSaveTrilha,
  onUpdateTrilha,
} from "./trilha/sagas";
import { TrilhaTypes } from "./trilha/types";
import {
  getUsuarioData,
  getUsuarioTable,
  onAtivarUsuario,
  onImportarUsuario,
  onImportarUsuarioT5,
  onInativarMassa,
  onInativarUsuario,
  onResetSenha,
  onResetSenhaMassa,
  onSaveUsuario,
  onUpdateUsuario,
} from "./usuario/sagas";
import { UsuarioTypes } from "./usuario/types";
import {
  getAllClusterList,
  getAllGrupoList,
  getAllMunicipioList,
  getAllSubclusterList,
  getAvaliacaoList,
  getAvaliacoesByPerfilAndEstado,
  getClusterList,
  getConteudoList,
  getCursoList,
  getSegurancaNoTrabalhoList,
  getEstadosList,
  getGestoresList,
  getMenuInfo,
  getMunicipioList,
  getPerfilList,
  getRegional,
  getSubclusterList,
  getGrupoClusterSubcluster,
  getTemaList,
  getUnidadeNegocioList,
  getUsuariosList,
  setLoading,
  getTrilhaList,
} from "./utils/sagas";
import { UtilTypes } from "./utils/types";
import { getStoryTable, getStoryData, onAtivarStory, onInativarStory, onSaveStory, onUpdateStory, onNotificacaoStory } from "./storys/sagas";
import { StoryTypes } from "./storys/types";
import { MunicipioTypes } from "./municipios/types";
import { getMunicipioData, getMunicipioTable, onSaveMunicipio } from "./municipios/sagas";
import { ChatBotCategoriaTypes } from "./chatbot/categoria/types";
import { 
  getChatBotCategoria, 
  getChatBotCategoriaSave, 
  getChatBotCategoriaUpdate, 
  getChatBotCategoriaDelete, 
  getChatBotCategoriaTable,
  getChatBotCategoriaKeyEmUso,
  getChatBotCategoriaList,
  getChatBotCategoriaDiagrama
} from "./chatbot/categoria/sagas";
import { getChatBotNivel, getChatBotNivelDelete, getChatBotNivelKeyEmUso, getChatBotNivelList, getChatBotNivelQtdChaves, getChatBotNivelSave, getChatBotNivelTable, getChatBotNivelUpdate } from "./chatbot/nivel/sagas";
import { ChatBotNivelTypes } from "./chatbot/nivel/types";
import { ChatBotTypes } from "./chatbot/types";
import { deleteChatBotLinkAuxiliar } from "./chatbot/sagas";
import { ChatBotSaudacaoTypes } from "./chatbot/saudacao/types";
import { deleteChatBotSaudacao, getChatBotSaudacao, getChatBotSaudacaoTable, postChatBotSaudacaoSave, putChatBotSaudacaoUpdate } from "./chatbot/saudacao/sagas";

export default function* rootSaga(): any {
  return yield all([
    takeLatest(AuthTypes.AUTH_LOGIN_REQUEST, loginRequest),
    takeLatest(AuthTypes.REFRESH_TOKEN, refreshToken),
    takeLatest(AuthTypes.AUTH_LOGOUT, doLogoutApp),
    takeLatest(UtilTypes.MENU_LIST, getMenuInfo),
    takeLatest(UtilTypes.GESTORES_LIST, getGestoresList),
    takeLatest(UtilTypes.ESTADOS_LIST, getEstadosList),
    takeLatest(UtilTypes.MUNICIPIOS_LIST, getMunicipioList),
    takeLatest(UtilTypes.REGIONAL, getRegional),
    takeLatest(UtilTypes.MUNICIPIO_ALL_LIST, getAllMunicipioList),
    takeLatest(UtilTypes.SUBCLUSTER_ALL_LIST, getAllSubclusterList),
    takeLatest(UtilTypes.GRUPO_ALL_LIST, getAllGrupoList),
    takeLatest(UtilTypes.SUBCLUSTER_LIST, getSubclusterList),
    takeLatest(UtilTypes.CLUSTER_ALL_LIST, getAllClusterList),
    takeLatest(UtilTypes.CLUSTER_LIST, getClusterList),
    takeLatest(UtilTypes.GRUPO_CLUSTER_SUBCLUSTER, getGrupoClusterSubcluster),
    takeLatest(UtilTypes.UNIDADE_NEGOCIO_LIST, getUnidadeNegocioList),
    takeLatest(UtilTypes.PERFIL_LIST, getPerfilList),
    takeLatest(UtilTypes.TEMA_LIST, getTemaList),
    takeLatest(UtilTypes.CONTEUDO_LIST, getConteudoList),
    takeLatest(UtilTypes.AVALIACAO_LIST, getAvaliacaoList),
    takeLatest(UtilTypes.CURSO_LIST, getCursoList),
    takeLatest(UtilTypes.SEGURANCA_LIST, getSegurancaNoTrabalhoList),
    takeLatest(UtilTypes.SET_LOADING, setLoading),
    takeLatest(UtilTypes.USUARIOS_LIST, getUsuariosList),
    takeLatest(UtilTypes.TRILHA_LIST, getTrilhaList),
    takeLatest(UsuarioTypes.USUARIO_TABLE_REQUEST, getUsuarioTable),
    takeLatest(UsuarioTypes.USUARIO_ATIVAR, onAtivarUsuario),
    takeLatest(UsuarioTypes.USUARIO_INATIVAR, onInativarUsuario),
    takeLatest(UsuarioTypes.USUARIO_INATIVAR_MASSA, onInativarMassa),
    takeLatest(UsuarioTypes.USUARIO_IMPORTAR, onImportarUsuario),
    takeLatest(UsuarioTypes.USUARIO_IMPORTAR_T5, onImportarUsuarioT5),
    takeLatest(UsuarioTypes.USUARIO_RESETAR_SENHA, onResetSenha),
    takeLatest(UsuarioTypes.USUARIO_RESETAR_SENHA_MASSA, onResetSenhaMassa),
    takeLatest(UsuarioTypes.USUARIO_DATA, getUsuarioData),
    takeLatest(UsuarioTypes.USUARIO_SAVE, onSaveUsuario),
    takeLatest(UsuarioTypes.USUARIO_UPDATE, onUpdateUsuario),
    takeLatest(PerfilTypes.PERFIL_TABLE_REQUEST, getPerfilTable),
    takeLatest(PerfilTypes.PERFIL_ATIVA_REQUEST, onAtivarPerfil),
    takeLatest(PerfilTypes.PERFIL_INATIVA_REQUEST, onInativarPerfil),
    takeLatest(PerfilTypes.PERFIL_DATA_REQUEST, getPerfilData),
    takeLatest(PerfilTypes.PERFIL_SAVE_REQUEST, onSavePerfil),
    takeLatest(PerfilTypes.PERFIL_UPDATE_REQUEST, onUpdatePerfil),
    takeLatest(PerfilTypes.PERFIL_EXISTENTE_REQUEST, getPerfilExistente),
    takeLatest(TemaTypes.TEMA_TABLE_REQUEST, getTemaTable),
    takeLatest(TemaTypes.TEMA_ATIVA_REQUEST, onAtivarTema),
    takeLatest(TemaTypes.TEMA_INATIVA_REQUEST, onInativarTema),
    takeLatest(TemaTypes.TEMA_DATA_REQUEST, getTemaData),
    takeLatest(TemaTypes.TEMA_SAVE_REQUEST, onSaveTema),
    takeLatest(TemaTypes.TEMA_UPDATE_REQUEST, onUpdateTema),
    takeLatest(TemaTypes.TEMA_EXISTENTE_REQUEST, getTemaExistente),
    takeLatest(UrlExternaTypes.URL_EXTERNA_TABLE_REQUEST, getUrlExternaTable),
    takeLatest(UrlExternaTypes.URL_EXTERNA_DATA_REQUEST, getUrlExternaData),
    takeLatest(UrlExternaTypes.URL_EXTERNA_ATIVA_REQUEST, onAtivarUrlExterna),
    takeLatest(
      UrlExternaTypes.URL_EXTERNA_INATIVA_REQUEST,
      onInativarUrlExterna
    ),
    takeLatest(UrlExternaTypes.URL_EXTERNA_SAVE_REQUEST, onSaveUrlExterna),
    takeLatest(UrlExternaTypes.URL_EXTERNA_UPDATE_REQUEST, onUpdateUrlExterna),
    takeLatest(
      UrlExternaTypes.URL_EXTERNA_PUSH_REQUEST,
      onNotificacaoUrlExterna
    ),
    takeLatest(ConteudoTypes.CONTEUDO_TABLE_REQUEST, getConteudoTable),
    takeLatest(ConteudoTypes.CONTEUDO_DATA_REQUEST, getConteudoData),
    takeLatest(ConteudoTypes.CONTEUDO_ATIVA_REQUEST, onAtivarConteudo),
    takeLatest(ConteudoTypes.CONTEUDO_INATIVA_REQUEST, onInativarConteudo),
    takeLatest(ConteudoTypes.CONTEUDO_SAVE_REQUEST, onSaveConteudo),
    takeLatest(ConteudoTypes.CONTEUDO_UPDATE_REQUEST, onUpdateConteudo),
    takeLatest(ConteudoTypes.CONTEUDO_PUSH_REQUEST, onNotificacaoConteudo),
    takeLatest(ConteudoTypes.CONTEUDO_TIPO_REQUEST, getConteudoTipoList),
    takeLatest(DestaqueTypes.DESTAQUE_TABLE_REQUEST, getDestaqueTable),
    takeLatest(DestaqueTypes.DESTAQUE_DATA_REQUEST, getDestaqueData),
    takeLatest(DestaqueTypes.DESTAQUE_ATIVA_REQUEST, onAtivarDestaque),
    takeLatest(DestaqueTypes.DESTAQUE_INATIVA_REQUEST, onInativarDestaque),
    takeLatest(DestaqueTypes.DESTAQUE_SAVE_REQUEST, onSaveDestaque),
    takeLatest(DestaqueTypes.DESTAQUE_UPDATE_REQUEST, onUpdateDestaque),
    takeLatest(CursoTypes.CURSO_TABLE_REQUEST, getCursoTable),
    takeLatest(CursoTypes.CURSO_DATA_REQUEST, getCursoData),
    takeLatest(CursoTypes.CURSO_ATIVA_REQUEST, onAtivarCurso),
    takeLatest(CursoTypes.CURSO_INATIVA_REQUEST, onInativarCurso),
    takeLatest(CursoTypes.CURSO_SAVE_REQUEST, onSaveCurso),
    takeLatest(CursoTypes.CURSO_UPDATE_REQUEST, onUpdateCurso),
    takeLatest(CursoTypes.CURSO_PUSH_REQUEST, onNotificacaoCurso),
    takeLatest(SegurancaNoTrabalhoTypes.SEGURANCA_TABLE_REQUEST, getSegurancaNoTrabalhoTable),
    takeLatest(SegurancaNoTrabalhoTypes.SEGURANCA_DATA_REQUEST, getSegurancaNoTrabalhoData),
    takeLatest(SegurancaNoTrabalhoTypes.SEGURANCA_ATIVA_REQUEST, onAtivarSegurancaNoTrabalho),
    takeLatest(SegurancaNoTrabalhoTypes.SEGURANCA_INATIVA_REQUEST, onInativarSegurancaNoTrabalho),
    takeLatest(SegurancaNoTrabalhoTypes.SEGURANCA_SAVE_REQUEST, onSaveSegurancaNoTrabalho),
    takeLatest(SegurancaNoTrabalhoTypes.SEGURANCA_UPDATE_REQUEST, onUpdateSegurancaNoTrabalho),
    takeLatest(SegurancaNoTrabalhoTypes.SEGURANCA_PUSH_REQUEST, onNotificacaoSegurancaNoTrabalho),
    takeLatest(ComunicadoTypes.COMUNICADO_TABLE_REQUEST, getComunicadoTable),
    takeLatest(ComunicadoTypes.COMUNICADO_DATA_REQUEST, getComunicadoData),
    takeLatest(ComunicadoTypes.COMUNICADO_ATIVA_REQUEST, onAtivarComunicado),
    takeLatest(
      ComunicadoTypes.COMUNICADO_INATIVA_REQUEST,
      onInativarComunicado
    ),
    takeLatest(ComunicadoTypes.COMUNICADO_SAVE_REQUEST, onSaveComunicado),
    takeLatest(ComunicadoTypes.COMUNICADO_UPDATE_REQUEST, onUpdateComunicado),
    takeLatest(
      ComunicadoTypes.COMUNICADO_PUSH_REQUEST,
      onNotificacaoComunicado
    ),
    takeLatest(T1Types.T1_TABLE_REQUEST, getT1Table),
    takeLatest(T1Types.T1_DATA_REQUEST, getT1Data),
    takeLatest(T1Types.T1_ATIVA_REQUEST, onAtivarT1),
    takeLatest(T1Types.T1_INATIVA_REQUEST, onInativarT1),
    takeLatest(T1Types.T1_SAVE_REQUEST, onSaveT1),
    takeLatest(T1Types.T1_UPDATE_REQUEST, onUpdateT1),
    takeLatest(T1Types.T1_PUSH_REQUEST, onNotificacaoT1),
    takeLatest(RelatorioTypes.EXTRAIR_RELATORIO_REQUEST, getRelatorio),
    takeLatest(TrilhaTypes.TRILHA_TABLE_REQUEST, getTrilhaTable),
    takeLatest(TrilhaTypes.TRILHA_DATA_REQUEST, getTrilhaData),
    takeLatest(TrilhaTypes.TRILHA_ATIVA_REQUEST, onAtivarTrilha),
    takeLatest(TrilhaTypes.TRILHA_INATIVA_REQUEST, onInativarTrilha),
    takeLatest(TrilhaTypes.TRILHA_SAVE_REQUEST, onSaveTrilha),
    takeLatest(TrilhaTypes.TRILHA_UPDATE_REQUEST, onUpdateTrilha),
    takeLatest(TrilhaTypes.TRILHA_PUSH_REQUEST, onNotificacaoTrilha),
    takeLatest(AvaliacaoTypes.AVALIACAO_TABLE_REQUEST, getAvaliacaoTable),
    takeLatest(AvaliacaoTypes.AVALIACAO_ATIVA_REQUEST, onAtivarAvaliacao),
    takeLatest(AvaliacaoTypes.AVALIACAO_INATIVA_REQUEST, onInativarAvaliacao),
    takeLatest(AvaliacaoTypes.AVALIACAO_DATA_REQUEST, getAvaliacaoData),
    takeLatest(AvaliacaoTypes.AVALIACAO_SAVE_REQUEST, onSaveAvaliacao),
    takeLatest(AvaliacaoTypes.AVALIACAO_UPDATE_REQUEST, onUpdateAvaliacao),
    takeLatest(AvaliacaoTypes.AVALIACAO_TIPO_REQUEST, getAvaliacaoTipoList),
    takeLatest(
      AvaliacaoTypes.AVALIACAO_IMPORTAR,
      onImportarAvalicaoPerguntaEResposta
    ),
    takeLatest(
      AvaliacaoTypes.AVALIACAO_PERGUNTA_SAVE_REQUEST,
      onPersistAvaliacaoPergunta
    ),
    takeLatest(
      AvaliacaoTypes.PERGUNTA_RESPOSTA_SAVE_REQUEST,
      onPersistPerguntaResposta
    ),
    takeLatest(
      AvaliacaoTypes.AVALIACAO_PUSH_REQUEST,
      onEnviaNotificacaoAvaliacao
    ),
    takeLatest(
      UtilTypes.AVALIACAO_BY_PERFIL_ESTADO,
      getAvaliacoesByPerfilAndEstado
    ),
    takeLatest(
      JustificativaTypes.JUSTIFICATIVA_DATA_REQUEST,
      getJustificativaData
    ),
    takeLatest(
      JustificativaTypes.JUSTIFICATIVA_SAVE_REQUEST,
      onSaveJustificativa
    ),
    takeLatest(
      JustificativaTypes.JUSTIFICATIVA_TABLE_REQUEST,
      getJustificativaTable
    ),
    takeLatest(
      JustificativaTypes.JUSTIFICATIVA_UPDATE_REQUEST,
      onUpdateJustificativa
    ),
    takeLatest(
      JustificativaTypes.JUSTIFICATIVA_INATIVA_REQUEST,
      onInativarJustificativa
    ),
    takeLatest(
      JustificativaTypes.JUSTIFICATIVA_TIPO_REQUEST,
      getJustificativaTipoList
    ),
    takeLatest(SubclusterTypes.SUBCLUSTER_SAVE_REQUEST, onSaveSubcluster),
    takeLatest(SubclusterTypes.SUBCLUSTER_DATA_REQUEST, getSubclusterData),
    takeLatest(
      SubclusterTypes.SUBCLUSTER_INATIVA_REQUEST,
      onInativarSubcluster
    ),
    takeLatest(SubclusterTypes.SUBCLUSTER_TABLE_REQUEST, getSubclusterTable),
    takeLatest(SubclusterTypes.SUBCLUSTER_UPDATE_REQUEST, onUpdateSubcluster),
    takeLatest(ClusterTypes.CLUSTER_DATA_REQUEST, getClusterData),
    takeLatest(ClusterTypes.CLUSTER_INATIVA_REQUEST, onInativarCluster),
    takeLatest(ClusterTypes.CLUSTER_SAVE_REQUEST, onSaveCluster),
    takeLatest(ClusterTypes.CLUSTER_TABLE_REQUEST, getClusterTable),
    takeLatest(ClusterTypes.CLUSTER_UPDATE_REQUEST, onUpdateCluster),
    takeLatest(GrupoTypes.GRUPO_DATA_REQUEST, getGrupoData),
    takeLatest(GrupoTypes.GRUPO_INATIVA_REQUEST, onInativarGrupo),
    takeLatest(GrupoTypes.GRUPO_SAVE_REQUEST, onSaveGrupo),
    takeLatest(GrupoTypes.GRUPO_TABLE_REQUEST, getGrupoTable),
    takeLatest(GrupoTypes.GRUPO_UPDATE_REQUEST, onUpdateGrupo),
    takeLatest(AgendaNotificacaoTypes.AGENDA_DATA_REQUEST, getAgendaData),
    takeLatest(AgendaNotificacaoTypes.AGENDA_INATIVA_REQUEST, onInativarAgenda),
    takeLatest(AgendaNotificacaoTypes.AGENDA_SAVE_REQUEST, onSaveAgenda),
    takeLatest(AgendaNotificacaoTypes.AGENDA_TABLE_REQUEST, getAgendaTable),
    takeLatest(AgendaNotificacaoTypes.AGENDA_UPDATE_REQUEST, onUpdateAgenda),
    takeLatest(StoryTypes.STORY_TABLE_REQUEST, getStoryTable),
    takeLatest(StoryTypes.STORY_DATA_REQUEST, getStoryData),
    takeLatest(StoryTypes.STORY_ATIVA_REQUEST, onAtivarStory),
    takeLatest(StoryTypes.STORY_INATIVA_REQUEST, onInativarStory),
    takeLatest(StoryTypes.STORY_SAVE_REQUEST, onSaveStory),
    takeLatest(StoryTypes.STORY_UPDATE_REQUEST, onUpdateStory),
    takeLatest(StoryTypes.STORY_PUSH_REQUEST, onNotificacaoStory),
    takeLatest(MunicipioTypes.MUNICIPIO_DATA_REQUEST, getMunicipioData),
    takeLatest(MunicipioTypes.MUNICIPIO_SAVE_REQUEST, onSaveMunicipio),
    takeLatest(MunicipioTypes.MUNICIPIO_TABLE_REQUEST, getMunicipioTable),
    takeLatest(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_REQ, getChatBotCategoria),
    takeLatest(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_SAVE_REQ, getChatBotCategoriaSave),
    takeLatest(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_UPDATE_REQ, getChatBotCategoriaUpdate),
    takeLatest(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DELETE_REQ, getChatBotCategoriaDelete),
    takeLatest(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_TABLE_REQ, getChatBotCategoriaTable),
    takeLatest(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_DIAGRAMA_REQ, getChatBotCategoriaDiagrama),
    takeLatest(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_KEY_REQ, getChatBotCategoriaKeyEmUso),
    takeLatest(ChatBotCategoriaTypes.CHATBOT_CATEGORIA_LIST_REQ, getChatBotCategoriaList),
    takeLatest(ChatBotNivelTypes.CHATBOT_NIVEL_REQ, getChatBotNivel),
    takeLatest(ChatBotNivelTypes.CHATBOT_NIVEL_SAVE_REQ, getChatBotNivelSave),
    takeLatest(ChatBotNivelTypes.CHATBOT_NIVEL_UPDATE_REQ, getChatBotNivelUpdate),
    takeLatest(ChatBotNivelTypes.CHATBOT_NIVEL_DELETE_REQ, getChatBotNivelDelete),
    takeLatest(ChatBotNivelTypes.CHATBOT_NIVEL_TABLE_REQ, getChatBotNivelTable),
    takeLatest(ChatBotNivelTypes.CHATBOT_NIVEL_KEY_REQ, getChatBotNivelKeyEmUso),
    takeLatest(ChatBotNivelTypes.CHATBOT_NIVEL_LIST_REQ, getChatBotNivelList),
    takeLatest(ChatBotNivelTypes.CHATBOT_NIVEL_QTD_CHAVES_REQ, getChatBotNivelQtdChaves),
    takeLatest(ChatBotTypes.CHATBOT_DELETE_LINK_AUX_REQ, deleteChatBotLinkAuxiliar), 
    takeLatest(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_REQ, getChatBotSaudacao),
    takeLatest(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_SAVE_REQ, postChatBotSaudacaoSave),
    takeLatest(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_UPDATE_REQ, putChatBotSaudacaoUpdate),
    takeLatest(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_DELETE_REQ, deleteChatBotSaudacao),
    takeLatest(ChatBotSaudacaoTypes.CHATBOT_SAUDACAO_TABLE_REQ, getChatBotSaudacaoTable),
  ]);
}
