import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import GenericTable from "../GenericTable/GenericTable";
import { AgendaObjTable, AgendaTable } from "../../store/ducks/agendaNotificacao/types";

const agendas: AgendaObjTable[] = [];
const col: GridColDef[] = [];

const AgendaTableComponent = (props: any) => {
  const [txtBusca, setTxtBusca] = useState("");
  const [columns, setColumns] = useState(col);
  const [listAgenda, setListAgenda] = useState(agendas);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [numberElements, setNumberElements] = useState(0);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    generateColumnsTable();
  }, []);

  useEffect(() => {
    if (props.data) {
      tranformObjectTable(props.data);
    }
  }, [props.data]);

  const generateColumnsTable = () => {
    const _columns: GridColDef[] = [
      { field: "nmTipoAgenda", headerName: "Tipo do Agendamento", width: 300 },
      { field: "nmObjeto", headerName: "Nome do Agendamento", width: 500 },
      { field: "dataAgenda", headerName: "Data do Agendamento", width: 400 },
    ];

    setColumns(_columns);
  };

  const tranformObjectTable = (obj: AgendaTable) => {
    setSize(obj.size);
    setPage(obj.number);
    setTotalElements(obj.totalElements);
    setTotalPages(obj.totalPages);
    setNumberElements(obj.numberOfElements);
    setOffset(obj.pageable.offset);

    let list: AgendaObjTable[] = obj.content.map((content: any) => {
      
      const item = content;
      return {
        id: item[0],
        nmTipoAgenda: item[1],
        nmObjeto: item[3],
        dataAgenda: item[2]
      };
    });

    setListAgenda(list);
  };

  const onPageChange = (pageIdx: any) => {
    props.body.pagina = pageIdx - 1;
    setPage(pageIdx - 1);
    props.onChange(props.body);
  };

  const onSizeChange = (_size: any) => {
    props.body.tamanho = _size;
    setSize(_size);
    props.onChange(props.body);
  };

  const onChangeBusca = (txt: string) => {
    props.body.txtBusca = txt;
    setTxtBusca(txt);
    if (txt.length >= 3) {
      props.onChange(props.body);
    } else {
      props.body.txtBusca = "";
      props.onChange(props.body);
    }
  };

  const onSortChange = (sort: any) => {
    if (sort != undefined) {
      let colunaSort =
        sort.field && sort.field == "nmTipoAgenda"
          ? "nmTipoAgenda"
          : sort.field == "dataAgenda"
          ? "dataAgenda"
          : "nmTipoAgenda";

      props.body.coluna = colunaSort;
      props.body.ordem = sort.sort.toUpperCase();
    } else {
      props.body.coluna = "dataAgenda";
      props.body.ordem = "ASC";
    }
    props.onChange(props.body);
  };

  return (
    <GenericTable
      loading={props.loading}
      titleTable="Agendamentos de Notificação Cadastrados"
      rows={listAgenda}
      columns={columns}
      page={page}
      totalPages={totalPages}
      onPageChange={onPageChange}
      offset={offset}
      numberElements={numberElements}
      totalElements={totalElements}
      size={size}
      sizeChange={onSizeChange}
      txtBusca={txtBusca}
      onChangePesquisa={onChangeBusca}
      selection={props.selection}
      idSelectionChange={(arr: any) => props.idSelectionChange(arr)}
      sortChange={onSortChange}
    />
  );
};

export default AgendaTableComponent;
