import { combineReducers } from "@reduxjs/toolkit";

import auth from './auth';
import utils from './utils'
import usuario from './usuario';
import perfil from './perfil';
import tema from './temas';
import urlExterna from './pesquisaExt';
import conteudo from './conteudo';
import curso from './curso';
import segurancaNoTrabalho from './segurancaNoTrabalho';
import destaque from './destaque';
import comunicado from './comunicado';
import t1 from './t1';
import relatorio from './relatorio';
import trilha from './trilha';
import avaliacao from './avaliacao';
import justificativa from './justificativa';
import cluster from './cluster';
import subcluster from './subcluster';
import grupo from './grupo';
import story from './storys';
import municipio from './municipios';
import categoria from './chatbot/categoria'
import nivel from './chatbot/nivel'
import chatbot from './chatbot'
import saudacao from './chatbot/saudacao'
import agenda from './agendaNotificacao'

const appReducer = combineReducers({
    auth: auth,
    utils: utils,
    usuario: usuario,
    perfil: perfil,
    tema: tema,
    urlExterna: urlExterna,
    conteudo: conteudo,
    destaque: destaque,
    curso: curso,
    segurancaNoTrabalho: segurancaNoTrabalho,
    comunicado: comunicado,
    t1: t1,
    relatorio: relatorio,
    trilha: trilha,
    avaliacao: avaliacao,
    justificativa: justificativa,
    cluster: cluster,
    subcluster: subcluster,
    grupo: grupo,
    agenda:agenda,
    story: story,
    municipio: municipio,
    categoria: categoria,
    nivel: nivel,
    chatbot: chatbot,
    saudacao: saudacao
});

const rootReducer = (state: any, action: any) => {
    if (action.type === 'FORCE_LOGOUT') {
        localStorage.clear();
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export default rootReducer;
