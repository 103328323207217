import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { api } from "../../services";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import Typography from '@mui/material/Typography';

import AcaoMenu from "../../Components/AcaoMenu";
import { ApplicationState } from "../../store";

import * as ConteudoActions from '../../store/ducks/conteudo/actions'
import ConteudoTableComponent from "../../Components/TableConteudo";
import ModalCadastroConteudo from "../../Components/ModalCadastroConteudo";
import Loading from "../../Components/LoadingComponent";
import AlertaConfirmaAcao from "../../Components/AlertaConfirmaAcao";
import { EntidadesAcoes } from "../../enums/EntidadesAcoesConfirmacao";

const _body = {
    pagina: 0,
    tamanho: 10,
    ordem: "ASC",
    coluna: "nmConteudo",
    txtBusca: "",
    idsConteudoTipo: [2, 5, 6, 7, 8, 9]
};

function Conteudo({
    conteudo,
    getConteudoTable,
    onInativarConteudo,
    getConteudoData,
    onSaveConteudo,
    onUpdateConteudo,
    onNotificacaoConteudo,
    getConteudoTipoList,
    clearConteudoData
}: any) {

    const [idsSelecionado, setIdSelecionado] = useState<number[]>([]);
    const [modalCadastroOpen, setModalCadastroOpen] = useState(false);
    const [body, setBody] = useState(_body);
    const [table, setTable] = useState(null);
    const [alertConfirmacaoOpen, setAlertConfirmacaoOpen] = useState(false);
    const [alertConfirmacaoTexto, setAlertConfirmacaoTexto] = useState('');
    const [alertConfirmacaoAcao, setAlertConfirmacaoAcao] = useState(() => () => {});
    const [municipios, setMunicipios] = useState([]);

    useEffect(() => {
        if(conteudo.error){
            alert('Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: ' + conteudo.errorMessage);
        }
    }, [conteudo.error]);

    useEffect(() => {
        if(!conteudo.loadingPersis) {
            handleCloseAndReload()
        }
    }, [conteudo.loadingPersis]);

    useEffect(() => {
        if(!modalCadastroOpen) {
            getConteudoTableData();
        }
    }, [body, modalCadastroOpen]);

    useEffect(() => {
        if(conteudo.data.table != null){
            setTable(conteudo.data.table);
        }

    }, [conteudo.data]);

    useEffect(() => {
        if(!conteudo.loadingInativacao){
            getConteudoTable(body);
        }
    }, [conteudo.loadingInativacao]);

    useEffect(() => {
        getConteudoTipoList()
    }, [])

    const getConteudoTableData = () => {
        getConteudoTable(body);
    }

    const handleCadastro = () => {
        clearConteudoData();
        setModalCadastroOpen(true)
    }

    function getConteudoDataMunicipio(data: any): any {
        const idConteudo = data;
        const token = localStorage.getItem('accessToken');
        
        const doRequest = () => {
            return api.get(`conteudo/findMunicipioByIdLimit?idConteudo=${idConteudo}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                
            }).then( (res: { data: any; }) => res.data)
            
        }
    
        try {
    
            return doRequest;
        } catch (error: any) {
        }
    };

    

    const handleEdicao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione um conteúdo que gostaria de alterar');

        } else if(idsSelecionado.length > 1) {
            alert('Selecione apenas um conteúdo para alteração!');

        } else {
            getConteudoData(idsSelecionado[0]);
            setMunicipios(getConteudoDataMunicipio(idsSelecionado[0]));
            setModalCadastroOpen(true);
        }
    }

    const handleInativacao = () => {
        if(idsSelecionado.length == 0) {
            alert('Selecione os conteúdos que gostaria de excluir');
        } else {
            setAlertConfirmacaoOpen(true);
            setAlertConfirmacaoTexto(EntidadesAcoes.CONTEUDOS_EXCLUSAO);
            setAlertConfirmacaoAcao(() => () => {
                onInativarConteudo(idsSelecionado);
                setAlertConfirmacaoOpen(false);
            })
        }
    }

    const changeTable = (__body: any) => {
        setBody({...__body});
    }

    const handlePersistConteudo = (edicao: boolean, conteudo: FormData) => {
        if(edicao) {
            onUpdateConteudo(conteudo);
            
        } else {
            onSaveConteudo(conteudo);
            
        }
    }

    const handleCloseAndReload = () => {
        clearConteudoData();
        setModalCadastroOpen(false);
        setIdSelecionado([]);
    }

    const unmountAlertConfirmacao = () => {
        setAlertConfirmacaoTexto('');
        setAlertConfirmacaoAcao(() => () => {})
        setAlertConfirmacaoOpen(false);
    }
    if(modalCadastroOpen) {
        return (
            <div className="pageBox">
                <Loading loading={conteudo.loading || conteudo.loadingPersis} />
                
                <ModalCadastroConteudo 
                    modalCadastroOpen={modalCadastroOpen} 
                    closeModal={() =>  handleCloseAndReload()}
                    conteudoData={conteudo.data.conteudo}
                    conteudoDataMunicipio={municipios} 
                    id = {idsSelecionado[0]}
                    persist={handlePersistConteudo}
                    enviaNotificacao={(idConteudo: any) => onNotificacaoConteudo(idConteudo)}
                    listConteudoTipo={conteudo.data.conteudoTipo}
                />
                
                
            </div>
        )       
    } else {
        return (
            <div className="pageBox">
                <Loading loading={conteudo.loading || conteudo.loadingPersis} />
                <AlertaConfirmaAcao openDialog={alertConfirmacaoOpen} texto={alertConfirmacaoTexto} execAcao={() => alertConfirmacaoAcao()} cancelaAcao={() => unmountAlertConfirmacao()} />
                <div className="menuLateral">
                    <Typography variant="h6" noWrap component="div" sx={{
                        width: '100%'
                    }} >
                        Ações
                        <AcaoMenu
                            onCadastro={handleCadastro} 
                            onEditar={handleEdicao} 
                            onInativar={handleInativacao} 
                        />
                    </Typography>
                </div>
                <div className="table">
                    <ConteudoTableComponent
                        loading={conteudo.loadingTable} 
                        data={table} 
                        body={body}
                        onChange={changeTable} 
                        selection={idsSelecionado}
                        idSelectionChange={(idsArr: number[]) => setIdSelecionado(idsArr)}/>
                </div>
            </div>
        )  
    }
    
};

const mapStateToProps = (state: ApplicationState) => ({
    conteudo: state.conteudo,
    utils: state.utils
});
  
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...ConteudoActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Conteudo);
