import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/pt-br";
import ListaAvaliacaoComponent from "../../Components/ListaAvaliacaoComponent";
import ListaConteudoComponent from "../../Components/ListaConteudoComponent";

import Loading from "../../Components/LoadingComponent";

import { ApplicationState } from "../../store";

import * as RelatorioActions from "../../store/ducks/relatorio/actions";
import * as JustificativaActions from "../../store/ducks/justificativa/actions";
import * as UtilsActions from "../../store/ducks/utils/actions";
import ListaPerfilComponent from "../../Components/ListaPerfilComponent";
import ListaEstadoComponent from "../../Components/ListaEstadoComponent";
import ListaTipoJustificativaComponent from "../../Components/ListaTipoJustificativaComponent";
import ListaGestorComponent from "../../Components/ListaGestorComponent";
import ListaCursoComponent from "../../Components/ListaCursoComponent";
import ListaMunicipioComponent from "../../Components/ListaMunicipioComponent";
import ListaTrilhaComponent from "../../Components/ListaTrilhaComponent";

function Relatorio({
  relatorio,
  getRelatorio,
  getPerfilList,
  getEstadosList,
  getAvaliacoesByPerfilAndEstado,
}: any) {
  const [idPerfis, setIdPerfis] = useState<any[]>([]);
  const [idEstados, setIdEstados] = useState<any[]>([]);
  const [tipoRelatorio, setTipoRelatorio] = useState("");
  const [dataInicio, setDataInicio] = useState<Dayjs | null | undefined>(null);
  const [dataFinal, setDataFinal] = useState<Dayjs | null | undefined>(null);
  const [avaliacao, setAvaliacoes] = useState([]);
  const [idsJustificativaTipo, setIdsJustificativaTipo] = useState([]);
  const [idsGestor, setIdsGestor] = useState([]);
  const [idsCursos, setIdsCursos] = useState([]);
  const [idsTrilhas, setIdsTrilhas] = useState([]);
  const [idsMunicipios, setIdsMunicipios] = useState([]);
  const [conteudo, setConteudo] = useState([]);

  useEffect(() => {
    if (relatorio.error) {
      alert(
        "Erro!\nAtenção, ocorreu um erro durante sua solicitação. Caso apareça essa mensagem novamente procure o Suporte:\nDescrição do erro: " +
          relatorio.errorMessage
      );
    }
  }, [relatorio.error]);

  useEffect(() => {
    getEstadosList();
    getPerfilList();
  }, []);

  const geraRelatorio = () => {
    let idsToFilter = [];
    let listGestoresId = null;
    let listTiposId = null;
    const idPerfisList = idPerfis.map((perfil) => perfil?.idPerfil);
    const idEstadosList = idEstados.map((estado) => estado?.idEstado);
    if (
      tipoRelatorio == "ANALITICO" ||
      tipoRelatorio == "AVALIACAONOTA" ||
      ((tipoRelatorio == "AVALIACAO" || tipoRelatorio === "JUSTIFICATIVA") &&
        avaliacao.length > 0 &&
        avaliacao.length <= 1000)
    ) {
      idsToFilter = avaliacao.map((item: any) => item.idAvaliacao);
    } else if (
      tipoRelatorio == "CONTEUDOSREALIZADOS" &&
      conteudo.length > 0 &&
      conteudo.length <= 1000
    ) {
      idsToFilter = conteudo.map((item: any) => item.idConteudo);
    }
    if (
      tipoRelatorio === "JUSTIFICATIVA" &&
      idsGestor.length > 0 &&
      idsGestor.length <= 1000
    ) {
      listGestoresId = idsGestor.map((item: any) => item.idUsuario);
    }
    if (
      tipoRelatorio === "JUSTIFICATIVA" &&
      idsJustificativaTipo.length > 0 &&
      idsJustificativaTipo.length <= 1000
    ) {
      listTiposId = idsJustificativaTipo.map(
        (item: any) => item.idTipoJustificativa
      );
    } 
    if(tipoRelatorio === "CURSOREALIZADO" && idsCursos.length > 0) {
      idsToFilter = idsCursos.map((item: any) => item.idCurso)
    }

    if(tipoRelatorio === "TRILHAREALIZADA" && idsTrilhas.length > 0) {
      idsToFilter = idsTrilhas.map((item: any) => item.idTrilha)
    }

    const relatorioBody = {
      tipoRelatorio: tipoRelatorio,
      dtInicial: dataInicio?.format("YYYY-MM-DD") || null,
      dtFinal: dataFinal?.format("YYYY-MM-DD HH:MM")  || null,
      idsToFilter: idsToFilter,
      idsGestor: listGestoresId,
      idsTipoJustificativa: listTiposId,
      idsPerfil: idPerfisList,
      idsEstado: idEstadosList,
      idsMunicipio: idsMunicipios.map((item: any) => item?.idMunicipio),
    };
    getRelatorio(relatorioBody);
  };

  function filterAvaliacoes() {
    const idPerfisList = idPerfis.map((perfil) => perfil?.idPerfil);
    const idEstadosList = idEstados.map((estado) => estado?.idEstado);
    const payload = {
      listIdPerfil: idPerfisList,
      listIdEstado: idEstadosList,
    };
    getAvaliacoesByPerfilAndEstado(payload);
    setAvaliacoes([]);
  }

  useEffect(() => {
    filterAvaliacoes();
  }, [idPerfis, idEstados]);

  return (
    <div className="pageBox">
      <Loading loading={relatorio.loading} />
      <div className="relatorio">
        <Typography variant="h6" component="h6">
          Extração de Relatórios
        </Typography>
        <Divider />
        <Grid
          sx={{ flexGrow: 1, marginTop: "1em", marginBottom: "1em" }}
          container
          spacing={2}
          rowSpacing={2}
        >
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-controlled-open-select-label">
                Tipo do Relatório
              </InputLabel>
              <Select
                required
                label="Tipo do Relatório"
                value={tipoRelatorio}
                onChange={(e) => {
                  setTipoRelatorio(e.target.value);
                  setIdEstados([]);
                  setIdPerfis([]);
                  setDataInicio(null);
                  setDataFinal(null);
                  setAvaliacoes([]);
                  setIdsJustificativaTipo([]);
                  setIdsGestor([]);
                }}
              >
                <MenuItem value="">Escolha...</MenuItem>
                <MenuItem value="TRILHA">
                  Geral de Trilhas Disponibilizadas
                </MenuItem>
                <MenuItem value="CURSO">
                  Geral de Cursos Disponibilizados
                </MenuItem>
                <MenuItem value="STORY">
                  Geral de Stories Disponibilizados
                </MenuItem>
                <MenuItem value="SEGURANCAS">
                  Geral de Segurança do Trabalho Disponibilizadas
                </MenuItem>
                <MenuItem value="CONTEUDO">
                  Geral de Conteúdos Disponibilizados
                </MenuItem>
                <MenuItem value="USUARIO">
                  Geral de Usuários Cadastrados
                </MenuItem>
                <MenuItem value="TREINAMENTO1PONTO">
                  Geral de Treinamentos de 1 Ponto Disponibilizados
                </MenuItem>
                <MenuItem value="COMUNICADO">
                  Geral de Comunicados Disponibilizados
                </MenuItem>
                <MenuItem value="AVALIACAONOTA">Avaliação - Notas</MenuItem>
                <MenuItem value="AVALIACAO">
                  Avaliação - Respostas dos Usuários
                </MenuItem>
                <MenuItem value="CONTEUDOSREALIZADOS">
                  Geral de Conteúdos Realizados
                </MenuItem>
                <MenuItem value="JUSTIFICATIVA">
                  Relatório de Justificativas
                </MenuItem>
                <MenuItem value="ANALITICO">Analítico por Avaliação</MenuItem>
                <MenuItem value="CURSOREALIZADO">
                  Geral de Cursos Realizados
                </MenuItem>
                <MenuItem value="SEGURANCAREALIZADA">
                  Geral das Segurança do Trabalho Realizadas
                </MenuItem>
                <MenuItem value="TRILHAREALIZADA">
                  Geral de Trilhas Realizadas
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {tipoRelatorio === "ANALITICO" ||
          tipoRelatorio === "JUSTIFICATIVA" ||
          tipoRelatorio == "AVALIACAONOTA" ||
          tipoRelatorio == "AVALIACAO" ||
          tipoRelatorio == "CONTEUDOSREALIZADOS" ? (
            <>
              <Grid item xs={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DesktopDatePicker
                    label="Data Inicial"
                    value={dataInicio}
                    onChange={(newValue) => setDataInicio(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DesktopDatePicker
                    label="Data Final"
                    value={dataFinal}
                    onChange={(newValue) =>
                      setDataFinal(newValue?.hour(23).minute(59).second(59))
                    }
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </>
          ) : null}

          {tipoRelatorio === "ANALITICO" ? (
            <>
              <Grid item xs={6}>
                <ListaPerfilComponent
                  perfis={idPerfis}
                  onChangePerfis={(value: any) => {
                    setIdPerfis(value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <ListaEstadoComponent
                  estados={idEstados}
                  onChangeEstados={(value: any) => {
                    setIdEstados(value);
                  }}
                />
              </Grid>
            </>
          ) : null}

          {tipoRelatorio === "ANALITICO" ||
          tipoRelatorio === "JUSTIFICATIVA" ||
          tipoRelatorio == "AVALIACAONOTA" ||
          tipoRelatorio == "AVALIACAO" ? (
            <Grid item xs={6}>
              <ListaAvaliacaoComponent
                avaliacoes={avaliacao}
                onChangeAvaliacoes={(value: any) => {
                  setAvaliacoes(value);
                }}
              />
            </Grid>
          ) : tipoRelatorio == "CONTEUDOSREALIZADOS" ? (
            <Grid item xs={6}>
              <ListaConteudoComponent
                multiple={true}
                conteudos={conteudo}
                onChangeConteudos={(value: any) => setConteudo(value)}
              />
            </Grid>
          ) : null}
          {tipoRelatorio === "JUSTIFICATIVA" ? (
            <>
              <Grid item xs={6}>
                <ListaGestorComponent
                  gestores={idsGestor}
                  onChangeGestores={(state: any) => {
                    setIdsGestor(state);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <ListaTipoJustificativaComponent
                  tiposJustificativa={idsJustificativaTipo}
                  onChangeTipoJustificativa={(state: any) => {
                    setIdsJustificativaTipo(state);
                  }}
                />
              </Grid>
            </>
          ) : null}
          {tipoRelatorio === "CURSOREALIZADO" || tipoRelatorio === "TRILHAREALIZADA" ? (
            <>
              {
                tipoRelatorio === "CURSOREALIZADO" ? (
                  <Grid item xs={4}>
                    <ListaCursoComponent
                      cursos={idsCursos}
                      onChangeCursos={(state: any) => {
                        setIdsCursos(state);
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={4}>
                    <ListaTrilhaComponent
                      trilhas={idsTrilhas}
                      onChangeTrilhas={(state: any) => {
                        setIdsTrilhas(state);
                      }}
                    />
                  </Grid>
                )
              }
              <Grid item xs={4}>
                <ListaPerfilComponent
                  perfis={idPerfis}
                  onChangePerfis={(value: any) => {
                    setIdPerfis(value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <ListaMunicipioComponent
                    municipios={idsMunicipios}
                    onChangeMunicipios={(value: any) => {
                      setIdsMunicipios(value);
                    }}
                  />
              </Grid>
            </>
          ) : null}
        </Grid>
        <div className="cadastroFooter">
          <Divider />
          <Button variant="contained" onClick={geraRelatorio}>
            Gerar Relatório
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  relatorio: state.relatorio,
  justificativa: state.justificativa,
  utils: state.utils,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { ...RelatorioActions, ...JustificativaActions, ...UtilsActions },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Relatorio);
