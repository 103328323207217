export enum EntidadesAcoes {
    USUARIO_INATIVACAO = 'Realizar a inativação do(s) usuário(s) selecionado(s)!',
    USUARIO_RESET_SENHA = 'Realizar o reset de senha do(s) usuário(s) selecionado(s)!',
    AVALIACAO_EXCLUSAO = 'Realizar a exclusão da(s) avaliação(ões) selecionada(s)!',
    CONTEUDOS_EXCLUSAO = 'Realizar a exclusão do(s) conteúdo(s) selecionado(s)!',
    DESTAQUES_EXCLUSAO = 'Realizar a exclusão do(s) destaque(s) selecionado(s)!',
    PERFIL_EXCLUSAO = 'Realizar a exclusão do(s) público(s) alvo selecionado(s)!',
    PESQUISA_EXT_EXCLUSAO = 'Realizar a exclusão da(s) pesquisa(s) externa selecionada(s)!',
    TEMAS_EXCLUSAO = 'Realizar a exclusão do(s) tema(s) selecionado(s)!',
    CURSO_EXCLUSAO = 'Realizar a exclusão do(s) curso(s) selecionado(s)!',
    SEGURANCA_EXCLUSAO = 'Realizar a exclusão do(s) Segurança do Trabalho(s) selecionado(s)!',
    TRILHA_EXCLUSAO = 'Realizar a exclusão da(s) trilhas(s) selecionada(s)!',
    T1_EXCLUSAO = 'Realizar a exclusão do(s) treinamento(s) de 1 ponto selecionado(s)!',
    COMUNICADO_EXCLUSAO = 'Realizar a exclusão do(s) comunicado(s) selecionado(s)!',
    STORY_EXCLUSAO = 'Realizar a exclusão do(s) story(ies) selecionado(s)!',
    CLUSTER_EXCLUSAO = 'Deseja realizar a exclusão?',
    SUBCLUSTER_EXCLUSAO = 'Deseja realizar a exclusão?',
    GRUPO_EXCLUSAO = 'Deseja realizar a exclusão?',
    AGENDA_EXCLUSAO = 'Deseja realizar a exclusão?',
    JUSTIFICATIVA_EXCLUSAO = 'Deseja realizar a exclusão?',
    CHATBOT_CATEGORIA_EXCLUSAO = 'Realizar a exclusão da(s) categoria(s) selecionada(s)!',
    CHATBOT_NIVEL_EXCLUSAO = 'Realizar a exclusão do(s) nível(is) selecionado(s)!',
    CHATBOT_LINK_AUXILIAR_EXCLUSAO = 'Realizar a exclusão de link auxiliar!',
    CHATBOT_SAUDACAO_EXCLUSAO = 'Realizar a exclusão da(s) saudação(ões) selecionada(s)!',
    CATEGORIA = 'Realizar a exclusão da categoria ',
    NIVEL= 'Realizar a exclusão do nível ',
    SUBNIVEL = 'Realizar a exclusão do subnível '
}