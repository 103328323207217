import { api } from "../../../services";
import { call, put} from "redux-saga/effects";
import {
    getAgendaDataFailure,
    getAgendaDataSuccess,
    getAgendaTableFailure,
    getAgendaTableSuccess,
    onInativarAgendaFailure,
    onInativarAgendaSuccess,
    onSaveAgendaFailure,
    onSaveAgendaSuccess,
    onUpdateAgendaFailure,
    onUpdateAgendaSuccess,
} from './actions';

function* getAgendaTable(data:any): any{
    const body = data.payload;
    const token = localStorage.getItem("accessToken");

    const doRequest = () => {
        return api
        .post("agendaNotificacao/listAllAgendaTable", body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res) => res.data);
    };

    try{

        yield put(getAgendaTableSuccess(yield call(doRequest)));
    } catch (error: any){
        yield put(getAgendaTableFailure(error.message));
    }
}


function* onInativarAgenda(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem("accessToken");
  
    const doRequest = () => {
      return api
        .post("agendaNotificacao/desativar", body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    };
  
    try {
      yield put(onInativarAgendaSuccess(yield call(doRequest)));
    } catch (error: any) {
      yield put(onInativarAgendaFailure(error.message));
    }
  }
  
  function* getAgendaData(data: any): any {
    const idAgenda = data.payload;
    const token = localStorage.getItem("accessToken");
  
    const doRequest = () => {
      return api
        .get(`agendaNotificacao/findById?idAgenda=${idAgenda}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    };
  
    try {
      yield put(getAgendaDataSuccess(yield call(doRequest)));
    } catch (error: any) {
      yield put(getAgendaDataFailure(error.message));
    }
  }
  
  function* onSaveAgenda(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem("accessToken");
  
    const doRequest = () => {
      return api
        .put("agendaNotificacao/save", body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    };
  
    try {
      yield put(onSaveAgendaSuccess(yield call(doRequest)));
    } catch (error: any) {
      yield put(onSaveAgendaFailure(error.message));
    }
  }
  
  function* onUpdateAgenda(data: any): any {
    const body = data.payload;
    const token = localStorage.getItem("accessToken");
  
    const doRequest = () => {
      return api
        .put("agendaNotificacao/save", body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);
    };
  
    try {
      yield put(onUpdateAgendaSuccess(yield call(doRequest)));
    } catch (error: any) {
      yield put(onUpdateAgendaFailure(error.message));
    }
  }
  
  export {
    getAgendaData,
    onInativarAgenda,
    getAgendaTable,
    onSaveAgenda,
    onUpdateAgenda
  };